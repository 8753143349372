<template>
	<div class="container">
		<!-- 固定背景 -->
		<div class="datiBackground"></div>
		<div style="height: 30px;"></div>
		<!-- 头部图片 -->
		<div class="top">
			<div style="font-size: 40px;color: red;">{{$route.query.points}}分</div>
			<div>本次答题得分</div>
			<div>耗时：{{time}}</div>
		</div>
		<!-- 答题题目 -->
		<div class="dati">
			<div style="font-size: 28px;font-weight: bold;padding: 20px 0px 0px 20px;">错题展示</div>
			<!-- 单选 -->
			<div class="dati_all" v-for="(item,index) in answerHistory.list" :key="item.id">
				<!-- 题目 -->
				<div class="title_row">
					<label class="number">{{index+1}}.</label>
					<div class="title">
						{{item.title}}
					</div>
				</div>
				<!-- 选项 -->
				<div v-for="(x,y) in item.options" :key="x.id">
					<div :class="['option',{'correct':(x.active && item.flag ),'error':(x.active && !item.flag)}]">
						<div class="option_key">{{x.K}}.</div>
						<div class="option_value">{{x.value}}</div>
					</div>
				</div>
				<!-- 解析 -->
				<div class="analysis">
					<div class="correct_top" v-if="item.flag">
						<div class="left">
							<img src="../../assets/img/correct.png">
							<div>回答正确</div>
						</div>
						<div class="right">
							+1分
						</div>
					</div>
					<div class="error_top" v-if="!item.flag">
						<div class="left">
							<img src="../../assets/img/error.png">
							<div>回答错误</div>
						</div>
						<div class="right">
							-1分
						</div>
					</div>
					<div class="analysis_bottom">
						<div>
							正确答案：{{item.trueAnswer}}
						</div>
					</div>
				</div>
			</div>
			<!-- 多选 -->
			<div class="dati_all" v-for="(item,index) in answerHistory.list1" :key="item.id">
				<!-- 题目 -->
				<div class="title_row">
					<label class="number"
					<label class="number">{{answerHistory.list.length+index+1}}.</label>
					<div class="title">
						{{item.title}}
					</div>
				</div>
				<!-- 选项 -->
				<div v-for="(x,y) in item.options" :key="x.id">
					<div :class="['option',{'error':(x.active&& !item.flag),'correct':(x.active && item.flag)}]">
						<div class="option_key">{{x.K}}.</div>
						<div class="option_value">{{x.value}}</div>
					</div>
				</div>
				<!-- 解析 -->
				<div class="analysis">
					<div class="correct_top" v-if="item.flag">
						<div class="left">
							<img src="../../assets/img/correct.png">
							<div>回答正确</div>
						</div>
						<div class="right">
							+2分
						</div>
					</div>
					<div class="error_top" v-if="!item.flag">
						<div class="left">
							<img src="../../assets/img/error.png">
							<div>回答错误</div>
						</div>
						<div class="right">
							{{item.checkNumber}}分
						</div>
					</div>
					<div class="analysis_bottom analysis_bottom_1">
						<div>
							正确答案：
						</div>
						<div class="analysis_bottom_1" v-for="(x,y) in item.trueAnswer" :key="x.id">
							{{x.K}}
						</div>
					</div>
				</div>
			</div>
			<!-- 判断 -->
			<div class="dati_all" v-for="(item,index) in answerHistory.list2" :key="item.id">
				<!-- 题目 -->
				<div class="title_row">
					<label class="number"
						>{{answerHistory.list.length + answerHistory.list1.length + index + 1}}.</label>
					<div class="title">
						{{item.title}}
					</div>
				</div>
				<!-- 选项 -->
				<div v-for="(x,y) in item.options" :key="x.id">
					<div :class="['option',{'correct':(x.active && item.flag),'error':(x.active && ! item.flag)}]">
						<div class="option_value">{{x.value}}</div>
					</div>
				</div>
				<!-- 解析 -->
				<div class="analysis">
					<div class="correct_top" v-if="item.flag">
						<div class="left">
							<img src="../../assets/img/correct.png">
							<div>回答正确</div>
						</div>
						<div class="right">
							+1分
						</div>
					</div>
					<div class="error_top" v-if="!item.flag">
						<div class="left">
							<img src="../../assets/img/error.png">
							<div>回答错误</div>
						</div>
						<div class="right">
							-1分
						</div>
					</div>
					<div class="analysis_bottom">
						<div>
							正确答案：{{item.judgedata == 0 ? '错':'对'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex'
	export default {
		name: "cj",
		data() {
			return {
				time: '',
				// 答题历史记录
				answerHistory: {},
			}
		},
		mounted() {
			if (this.$route.query.id == 1) {
				let time = this.$route.query.tiem;
				let m = parseInt(time / 60 % 60)
				let s = parseInt(time % 60);
				this.time = (m < 10 ? '0' + m : m) + '分' + (s < 10 ? '0' + s : s) + '秒'
				this.getHistory()
			} else if (this.$route.query.id == 2) {
				this.getTime()
				if (sessionStorage.getItem('answerHistory') != null) {
					this.getHistory()
				}
			}
		},
		methods: {
			...mapActions(['changeTime', 'changeHistory']),
			// 获取模拟答题用时
			getTime() {
				this.changeTime({
					answerTime: sessionStorage.getItem('time')
				})
				this.time = this.$store.state.answerTime;
			},
			// 获取历史答题记录
			getHistory() {
				this.changeHistory({
					history: JSON.parse(sessionStorage.getItem('answerHistory'))
				})
				// 获取vuex中的答题记录
				this.answerHistory = this.$store.state.answerHistory;
				// console.log(this.$store.state.answerHistory)
				// 单选
				this.answerHistory.list.forEach((item, index) => {
					// 获取正确答案
					let trueAnswer = item.options.filter(e => {
						return e.answer;
					})
					// console.log(trueAnswer, "获取正确答案单选")
					// 显示题目的正确的答案
					this.answerHistory.list[index].trueAnswer = trueAnswer[0].K
					// 获取选中答案
					let selectAnswer = item.options.filter(e => {
						return e.active
					})
					// console.log(selectAnswer[0], "获取选中答案单选")
					if (selectAnswer[0] == undefined) {
						console.log('时间用完了单选')
					} else {
						// 判断选中的答案是否正确
						if (selectAnswer[0].answer == undefined) {
							// console.log("错误")
						} else {
							// console.log("正确")
							this.answerHistory.list[index].flag = true;
						}
					}
				})

				// 多选
				this.answerHistory.list1.forEach((item, index) => {
					// 获取正确的答案
					let trueAnswer = item.options.filter(e => {
						return e.answer;
					})
					console.log(trueAnswer, "正确答案多选")
					// 获取并显示多选题的正确结果
					this.answerHistory.list1[index].trueAnswer = trueAnswer;
					// 获取选中的结果
					let selectAnswer = item.options.filter(e => {
						return e.active;
					})
					// console.log(selectAnswer, "获取选中的答案多选")
					// 判断选中的结果是否全部包含answer字段
					let isAnswer = selectAnswer.every(e => {
						return e.answer
					})
					// console.log(isAnswer, "正确的")
					if (selectAnswer.length == 0) {
						console.log('时间已用完多选')
						this.answerHistory.list1[index].checkNumber = '0'
					} else {
						if (trueAnswer.length == selectAnswer.length && isAnswer) {
							this.answerHistory.list1[index].flag = true;
							console.log('+2分')
						} else if (isAnswer) {
							this.answerHistory.list1[index].checkNumber = '+1'
							console.log('+1分,对一半')
						} else {
							this.answerHistory.list1[index].checkNumber = '0'
							console.log('全错')
						}
					}
					// console.log(this.answerHistory.list1, "是否包含flag字段用来判断当前题是否答对")
				})

				// 判断
				this.answerHistory.list2.forEach((item, index) => {
					// 通过item.judgedata判断是否正确 1：正确  0：错误
					if ((item.judgedata == 0 && item.answer == 0) || (item.judgedata == 1 && item.answer == 1)) {
						console.log("答对了")
						// this.answerHistory.list2[index].judgedata = "错"
						this.answerHistory.list2[index].flag = true
					} else {
						console.log(item, "答题打错")
						this.answerHistory.list2[index].flag = false
					}

					// 获取正确答案
					// let trueAnswer = item.options.filter(e => {
					// 	return e.answer
					// })
					// console.log(trueAnswer, "获取判断题的正确答案")
					// this.answerHistory.list2[index].trueAnswer = trueAnswer[0].key;
					// // 获取选中答案
					// let selectAnswer = item.options.filter(e => {
					// 	return e.active
					// })
					// // console.log(selectAnswer[0],"获取选中答案判断")
					// // 判断选中的答案是否正确
					// if (selectAnswer[0] == undefined) {
					// 	console.log('时间用完了判断')
					// } else {
					// 	if (selectAnswer[0].answer == undefined) {
					// 		// console.log("错误")
					// 	} else {
					// 		// console.log("正确")
					// 		this.answerHistory.list2[index].flag = true;
					// 	}
					// }
				})
			}
		},
		beforeDestroy() {
			if (this.$route.query.id == 2) {
				sessionStorage.removeItem('answerHistory')
			}
		}
	}
</script>

<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding-bottom: 30px;
		.datiBackground {
			background: url(../../assets/img/datiBackground.jpg) no-repeat center;
			background-size: 100% 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -20;
		}

		.top {
			width: 70%;
			height: 230px;
			background-size: 100% 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background: url(../../assets/img/cjyu.png) no-repeat center;
			background-size: contain;
			margin: auto;
			div {
				font-size: 30px;
				color: #fff;
				font-weight: 600;
				letter-spacing: 10px;
				text-indent: 10px;
				font-family: 'fenshu';
			}
		}

		.time {
			width: 90%;
			height: 80px;
			background: #fff;
			border-radius: 20px;
			margin: 0 auto;
			font-size: 30px;
			font-weight: 600;
			line-height: 80px;
			display: flex;
			justify-content: space-around;

			.timeflex {
				display: flex;
				flex-direction: row;
				align-items: center;

				.t {
					color: red;
					font-size: 35px;
				}
			}
		}

		.dati {
			width: 90%;
			background: #fff;
			border-radius: 20px;
			margin: 30px auto;
			height: 1100px;
			overflow-y: scroll;

			.dati_all {
				padding: 30px;

				.title_row {
					width: 95%;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					padding-top: 40px;
					padding-bottom: 40px;
					margin: auto;
					font-size: 24px;
					font-weight: bold;

					.number {
						// width: 55px;
						// height: 55px;
						// background: #CCCCCC;
						// color: #fff;
						// border-radius: 50%;
						// text-align: center;
						// line-height: 50px;
						// font-size: 30px;
					}

					.title {
						width: 550px;
						// font-size: 35px;
						font-weight: 600;
						align-self: center;
					}
				}

				.option {
					display: flex;
					width: 95%;
					height: 80px;
					border-radius: 5px;
					font-size: 20px;
					align-items: center;
					margin: 0 auto 20px auto;
					background-color: #f2f2f2;

					.option_key {
						margin-left: 50px;
						font-weight: 600;
					}

					.option_value {
						margin-left: 30px;
					}
				}

				.analysis {
					width: 90%;
					margin: 0 auto;

					.correct_top {
						width: 100%;
						height: 55px;
						background-color: #E6F4FF;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.left {
							display: flex;
							align-items: center;
							font-size: 30px;
							color: #278CE6;
							margin-left: 20px;

							img {
								width: 40px;
								height: 40px;
								margin-right: 25px;
							}

						}

						.right {
							font-size: 30px;
							color: #278CE6;
							margin-right: 30px;
						}
					}

					.error_top {
						width: 100%;
						height: 55px;
						background-color: #FEE5E8;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.left {
							display: flex;
							align-items: center;
							font-size: 30px;
							color: #E72727;
							margin-left: 20px;

							img {
								width: 40px;
								height: 40px;
								margin-right: 25px;
							}

						}

						.right {
							font-size: 30px;
							color: #E72727;
							margin-right: 30px;
						}
					}

					.analysis_bottom {
						width: 100%;
						height: 80px;
						background-color: #F4F4F4;
						border-radius: 10px;
						font-size: 30px;
						line-height: 80px;

						div:first-child {
							margin-left: 35px;
							color: #949494;
						}

						div:nth-child(n) {
							margin-right: 10px;
						}
					}

					.analysis_bottom_1 {
						display: flex;
					}
				}
			}

			.dati_button {
				padding: 100px 0 80px 0;

				div {
					margin: 0 auto;
					width: 600px;
					height: 80px;
					background-color: #52ABEF;
					color: #fff;
					font-size: 40px;
					border-radius: 50px;
					text-align: center;
					line-height: 80px;
				}
			}
		}

		.correct {
			background-color: #318DD6 !important;
			color: #fff;
		}

		.error {
			background-color: #E62526 !important;
			color: #fff;
		}
	}
</style>
